import React, { useState } from 'react';
import "./TopBar.css";
// import {DateTime} from "luxon";
// Icons Import
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import { AiFillCaretDown } from "react-icons/ai";
import {AiOutlineUser} from 'react-icons/ai';

// Components Import
import LogoutCard from './LogoutCard';
import {AnimatePresence} from 'framer-motion';

// detects click outside component
import { useDetectClickOutside } from 'react-detect-click-outside';

import { useSelector } from 'react-redux';


function Topbar({miniSidebar, setMiniSidebar,setMobileSidebar,mobileSidebar}) {

    const user = useSelector(state => state.user);

    const [logoutCard, setLogoutCard] = useState(false);

    const outsideRef = useDetectClickOutside({ onTriggered: () =>  setLogoutCard(false)});

    const showLogoutCard = () => {
        setLogoutCard(!logoutCard);
    }

    return (
        <>
        {/* logo for small screen */}
        <div className=" lg:hidden bg-white h-16 flex items-center justify-center">
            <img src = "/images/corcym.png" draggable={false} className="py-2 h-full select-none"/> 
        </div>
        {/* logo for small screen */}
        <div className = "lg:pr-4 flex-shrink-0 flex bg-gray-light justify-between w-full items-center relative h-16 lg:h-20">

            {/* hamburger for desktop screen */}
            <div className="hidden bg-gray-bg-dark lg:flex items-center h-full px-5 cursor-pointer" 
            onClick={()=>{setMiniSidebar(!miniSidebar)}}>
                <GiHamburgerMenu className="text-2xl" />
            </div>

            {/* hamburger for small screen */}
            <div className="bg-gray-bg-dark lg:hidden flex items-center h-full px-5 cursor-pointer" 
            onClick={
                ()=>{
                setMobileSidebar(!mobileSidebar);
                }
            }>
                <GiHamburgerMenu className="text-2xl" />
            </div>

            <div className="flex items-center justify-between px-2 w-full">
                <div className="flex items-center">
                    <img className="w-48 hidden lg:block" src="/images/corcym.png" />
                    
                    <h1 className="hidden lg:block ml-3 text-base 2xl:text-lg " >
                        Welcome  {user.role}
                    </h1>  
                </div>
                <div className="flex items-center cursor-pointer" onClick={showLogoutCard} ref={outsideRef}>
                    <span className="p-2 rounded-full bg-gray-dark">
                    <AiOutlineUser className="text-2xl text-gray-text" />
                    </span>
                    <AiFillCaretDown />
                </div>
            </div> 
            <AnimatePresence exitBeforeEnter>
            {
                logoutCard ?
                <LogoutCard />
                :
                null
            }
            </AnimatePresence>

            {/* <div onClick={() => setAsideVisibility(!asideVisibility)}
            className="z-10000 relative lg:hidden h-full flex items-center cursor-pointer">
                <span className = "text-lg text-blue-primary">
                    {asideVisibility ? 
                        <FaTimes />
                    :
                        <GiHamburgerMenu />
                    }
                </span>
            </div> */}
            
        </div>
            
        </>
    )
}

export default Topbar
